<template>
    <div class="w-full h-full flex flex-col items-center justify-center">
        <div class="w-full h-full flex justify-center items-center">
            <h3 v-if="pageName !== 'Contacte-nos'" class="md:text-sm lg:text-md text-white font-poppins hover:text-purple-500 hover:cursor-pointer">{{ pageName }}</h3>
            <div v-if="pageName === 'Contacte-nos'" class="md:w-32 w-full h-full rounded-3xl border border-purple-500 bg-purple-500 hover:cursor-pointer">
                <p class="text-white font-poppins text-center p-2 md:text-sm lg:text-md">Contacte-nos</p>
            </div>
        </div>
        <div v-if="activePage === 'true'" class="w-fit h-full flex justify-center items-center">
            <div class="w-2 h-2 bg-gradient-to-l from-purple-500 to-purple-300 rounded-3xl"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pageName: String,
        activePage: String,
    }
}
</script>

<style>
</style>