<template>
    <div class="w-full md:w-9/12 lg:w-6/12 h-full flex flex-col items-end justify-center">
        <div class="w-full h-20 flex flex-row justify-center items-center gap-20">
            <div class="w-full h-full flex flex-col items-start justify-center" :class="{ 'justify-end mt-6': validUserName === false, 'justify-center mt-0': validUserName !== false }">
                <input :class="{'border-purple-500': userNameFocused, 'border-zinc-400': userNameFocused === false, 'border-rose-600': validUserName === false}" class="w-full h-3/6 text-sm bg-transparent rounded-none border-b font-poppins text-white placeholder-zinc-400 outline-none" type="text" v-model="userName" placeholder="Nome *" @focus="setUserNameFocus(true)" @blur="setUserNameFocus(false)"/>
                <p v-show="validUserName === false" class="text-start text-rose-600 mt-1 text-sm">* Campo obrigatório</p>
            </div>
            <div class="w-full h-full flex flex-col items-start justify-center" :class="{ 'justify-end mt-6': validUserCompany === false, 'justify-center mt-0': validUserCompany !== false }">
                <input :class="{'border-purple-500': userCompanyFocused, 'border-zinc-400': userCompanyFocused === false,'border-rose-600': validUserCompany === false}"  class="w-full h-3/6 text-sm bg-transparent rounded-none border-b font-poppins text-white placeholder-zinc-400 outline-none" type="text" v-model="userCompany" placeholder="Empresa *" @focus="setUserCompanyFocus(true)" @blur="setUserCompanyFocus(false)"/>
                <p v-show="validUserCompany === false" class="text-start text-rose-600 mt-1 text-sm">* Campo obrigatório</p>
            </div>
        </div>
        <div class="w-full h-20 flex flex-row justify-center items-start">
            <div class="w-full h-full flex flex-col items-start justify-center" :class="{ 'justify-end': validUserEmail === false, 'justify-center mt-0': validUserEmail !== false }">
                <input :class="{'border-purple-500': userEmailFocused, 'border-zinc-400': userEmailFocused === false, 'border-rose-600': validUserEmail === false}" class="w-full h-3/6 text-sm bg-transparent rounded-none border-b font-poppins text-white placeholder-zinc-400 outline-none" type="email" v-model="userEmail" placeholder="Email *" @focus="setUserEmailFocus(true)" @blur="setUserEmailFocus(false)"/>
                <p v-show="validUserEmail === false" class="text-start text-rose-600 mt-1 text-sm">* Campo obrigatório</p>
            </div>
        </div>
        <div class="w-full h-20 flex flex-row justify-center items-start">
            <div class="w-full h-full flex flex-col items-start justify-center" :class="{ 'justify-end': validMessageSubject === false, 'justify-center mt-0': validMessageSubject !== false }">
                <input :class="{'border-purple-500': messageSubjectFocused, 'border-zinc-400': messageSubjectFocused === false, 'border-rose-600': validMessageSubject === false}" class="w-full h-3/6 text-sm bg-transparent rounded-none border-b font-poppins text-white placeholder-zinc-400 outline-none" type="text" v-model="messageSubject" placeholder="Assunto *" @focus="setMessageSubjectFocused(true)" @blur="setMessageSubjectFocused(false)"/>
                <p v-show="validMessageSubject === false" class="text-start text-rose-600 mt-1 text-sm">* Campo obrigatório</p>
            </div>
        </div>
        <div class="w-full h-40 flex flex-row justify-center items-start">
            <div class="w-full h-full flex flex-col items-start justify-center mt-6" :class="{ 'justify-end': validMessageBody === false, 'justify-center mt-0': validMessageBody !== false }">
                <textarea :class="{'border-purple-500': messageBodyFocused, 'border-zinc-400': messageBodyFocused === false, 'border-rose-600': validMessageBody === false}" class="w-full h-full text-sm bg-transparent rounded-none border-b font-poppins text-white placeholder-zinc-400 outline-none resize-none" type="text" v-model="messageBody" placeholder="Mensagem *" @focus="setMessageBodyFocused(true)" @blur="setMessageBodyFocused(false)"></textarea>
                <p v-show="validMessageBody === false" class="text-start text-rose-600 mt-1 text-sm">* Campo obrigatório</p>
            </div>
        </div>
        <div class="w-full h-20 flex flex-row justify-end items-end">
            <a class="w-4/12 h-3/6 relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden text-indigo-600 transition duration-300 ease-out border border-white rounded-full shadow-md group hover:border-purple-500 hover:bg-purple-500 hover:cursor-pointer" @click="sendMessage" @mouseleave="setMessageSentState(false)">
                <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-purple-500 group-hover:translate-x-0 ease">
                    <p class="font-poppins" v-show="messageSent">Enviado</p>
                    <svg v-show="messageSent !== true" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                    </svg>
                </span>
                <span class="absolute flex items-center justify-center w-full h-full text-white transition-all duration-300 transform group-hover:translate-x-full ease font-regular font-poppins">Enviar</span>
                <span class="relative invisible font-poppins">Enviar</span>
            </a>

        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            baseURL: process.env.VUE_APP_API_URL,
            headersConfig: {
                headers: {
                    Authorization: process.env.VUE_APP_API_TOKEN
                }
            },
            userName: "",
            userCompany: "",
            userEmail: "",
            messageSubject: "",
            messageBody: "",
            
            userNameFocused: false,
            userCompanyFocused: false,
            userEmailFocused: false,
            messageSubjectFocused: false,
            messageBodyFocused: false,

            validUserName: null,
            validUserCompany: null,
            validUserEmail: null,
            validMessageSubject: null,
            validMessageBody: null,

            messageSent: false,
        }
    },
    methods: {
        setUserNameFocus(state) {
            this.userNameFocused = state;
        },
        setUserCompanyFocus(state) {
            this.userCompanyFocused = state;
        },
        setUserEmailFocus(state) {
            this.userEmailFocused = state;
        },
        setMessageSubjectFocused(state) {
            this.messageSubjectFocused = state;
        },
        setMessageBodyFocused(state) {
            this.messageBodyFocused = state;
        },
        setMessageSentState(state) {
            this.messageSent = state;
        },

        // Temporary Validation Methods
        validateUserName(userName) {
            this.validUserName = userName.length > 0;
        },
        validateUserCompany(userCompany) {
            this.validUserCompany = userCompany.length > 0;
        },
        validateUserEmail(userEmail) {
            this.validUserEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(userEmail);
        },
        validateMessageSubject(messageSubject) {
            this.validMessageSubject = messageSubject.length > 0;
        },
        validateMessageBody(messageBody) {
            this.validMessageBody = messageBody.length > 0;
        },
        validateFormData() {
            this.validateUserName(this.userName);
            this.validateUserCompany(this.userCompany);
            this.validateUserEmail(this.userEmail);
            this.validateMessageSubject(this.messageSubject);
            this.validateMessageBody(this.messageBody);

            if (this.validUserName && this.validUserCompany && this.validUserEmail && this.validMessageSubject && this.validMessageBody) return true;
            else return false;
        },
        async sendMessage() {
            const validFormData = this.validateFormData();
            if (validFormData) {
                this.setMessageSentState(true);

                const userData = {
                    userAccountTypeID: this.userCompany.length > 1 ? 1 : 2,
                    userName: this.userName,
                    userCompany: this.userCompany,
                    userEmail: this.userEmail
                }

                const messageData = {
                    sentDate: {
                        day: 0,
                        month: 0,
                        year: 0,
                        hours: 0,
                        minutes: 0,
                        seconds: 0,
                        milliseconds: 0,
                    },
                    userName: userData.userName,
                    authorID: 0,
                    companyName: userData.userCompany.length > 1 ? userData.userCompany : "INDIVIDUAL",
                    userEmail: userData.userEmail,
                    messageSubject: this.messageSubject,
                    messageBody: this.messageBody,
                }

                console.log(process.env.VUE_APP_API_TOKEN);

                try {
                    const response = await axios.get(`${this.baseURL}/`, this.headersConfig); // Use headersConfig
                    console.log(response.status);
                } catch (error) {
                    console.error('Erro na requisição', error);
                }


                const formatDate = () => {
                    let date = new Date();
                    date.getUTCDate() >= 10 ? messageData.sentDate.day = date.getUTCDate() : messageData.sentDate.day = parseInt(`0${date.getUTCDate()}`);
                    date.getMonth() >= 10 ? messageData.sentDate.month = date.getMonth() : messageData.sentDate.month = parseInt(`0${date.getMonth()}`);
                    messageData.sentDate.year = date.getFullYear();
                    date.getHours() >= 10 ? messageData.sentDate.hours = date.getHours() : messageData.sentDate.hours = parseInt(`0${date.getHours()}`);
                    date.getMinutes() >= 10 ? messageData.sentDate.minutes = date.getMinutes() : messageData.sentDate.minutes = parseInt(`0${date.getMinutes()}`);
                    date.getSeconds() >= 10 ? messageData.sentDate.seconds = date.getSeconds() : messageData.sentDate.seconds = parseInt(`0${date.getSeconds()}`);
                    date.getMilliseconds() >= 10 ? messageData.sentDate.milliseconds = date.getMilliseconds() : messageData.sentDate.milliseconds = parseInt(`0${date.getMilliseconds()}`);
                }



                const userExists = (await axios.get(`${this.baseURL}/checkIfUserExists/${userData.userEmail}`, this.headersConfig)).data;

                console.log(userExists);
                if (!userExists) {
                    console.log(userExists);
                    const userAddedResponse = (await axios.post(`${this.baseURL}/addUser`, userData, this.headersConfig));
                    if (userAddedResponse.status === 200) {
                        formatDate();
                        messageData.authorID = (await axios.get(`${this.baseURL}/getUserIDByEmail/${messageData.userEmail}`, this.headersConfig)).data;
                        const messageAddedResponse = (await axios.post(`${this.baseURL}/addMessage`, messageData, this.headersConfig));
                        if (messageAddedResponse.status === 200) {
                            const mailSentResponse = (await axios.post(`${this.baseURL}/sendEmail`, messageData, this.headersConfig)).status;
                            mailSentResponse === 200 ? this.setMessageSentState(true) : this.setMessageSentState(false);
                        }

                    }
                } else {
                    formatDate();
                    const messageAddedResponse = (await axios.post(`${this.baseURL}/addMessage`, messageData, this.headersConfig));
                    if (messageAddedResponse.status === 200) {
                        const mailSentResponse = (await axios.post(`${this.baseURL}/sendEmail`, messageData, this.headersConfig)).status;
                        mailSentResponse === 200 ? this.setMessageSentState(true) : this.setMessageSentState(false);
                    }
                }
            }
        }
    },
}
</script>