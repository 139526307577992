<template>
    <div class="w-full h-full md:h-screen flex flex-col items-center justify-center bg-black">
        <div class="w-full h-16">
            <Navbar activePage="Contacts"/>
        </div>
        <div class="w-full h-full bg-black flex flex-col md:flex-row items-start justify-start ju pl-16 pr-16 pt-20 pb-10">
            <div class="w-fit h-fit">
                <h3 class="text-white font-poppins font-semibold text-5xl">Fale <br>Connosco</h3>
                <div class="w-full h-fit mt-3">
                    <p class="text-white font-poppins text-sm">Tem uma ideia? <br>Vamos torná-la realidade.</p>
                </div>
            </div>
            <div class="w-full h-5/6 flex flex-col sm:flex-row justify-center sm:justify-end items-end mt-16">
                <ContactForm />
            </div>
        </div>
    </div>
</template>

<script>
import Navbar from "@/components/navbar/Navbar.vue";
import ContactForm from "@/components/contactForm/ContactForm.vue";

export default {
    components: { Navbar, ContactForm }
}
</script>

<style>

</style>