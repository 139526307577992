<template>
    <p class="text-pink-300">Rita</p>
</template>

<script>
export default {

}
</script>

<style>
</style>