<template>
    <div class="fixed w-full h-16 z-50 border-b bg-black border-stone-700">
        <nav class="w-full h-full flex justify-between">
            <div class="w-1/6 h-full flex justify-center items-center bg-blue-300">
                <a class="w-fit h-fit text-3xl" href="/">Infixa</a>
            </div>
            <div class="block md:hidden w-full h-full flex justify-end items-center mr-3">
                <div class="block md:hidden w-full h-full flex flex-row justify-end items-center gap-3">
                    <button :class="{'bg-purple-500': activePage === 'Contacts', 'bg-transparent': activePage !== 'Contacts'}" class="w-4/12 h-4/6 border border-purple-500 rounded-3xl text-white font-poppins hover:bg-purple-500 hover:cursor-pointer">Contacte-nos</button>
                    <button @click="toggleNavbar" class="text-purple-500">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="hidden md:flex md:w-4/5 lg:w-4/6 flex flex-row items-center justify-between">
                <div class="w-full p-4 mx-2">
                    <NavbarItem pageName="Início" activePage="false"/>
                </div>
                <div class="w-full p-4 mx-2">
                    <NavbarItem pageName="Soluções" activePage="false"/>
                </div>
                <div class="w-full p-4 mx-2">
                    <NavbarItem pageName="Serviços" activePage="false"/>
                </div>
                <div class="w-full p-5">
                    <NavbarItem pageName="A Infixa" activePage="false"/>
                </div>
                <div class="w-full  p-4 mx-2">
                    <NavbarItem pageName="Contacte-nos" activePage="false"/>
                </div>
            </div>

            <div v-show="navbarVisible" class="bg-black md:hidden absolute top-16 left-0 w-full h-full flex flex-col items-center justify-between">
                <div class="w-full p-4 mx-2">
                    <NavbarItem pageName="Início" activePage="true"/>
                </div>
                <div class="w-full p-4 mx-2">
                    <NavbarItem pageName="Soluções" activePage="false"/>
                </div>
                <div class="w-full p-4 mx-2">
                    <NavbarItem pageName="Serviços" activePage="false"/>
                </div>
                <div class="w-full p-4 mx-2">
                    <NavbarItem pageName="A Infixa" activePage="false"/>
                </div>
            </div>
        </nav>
    </div>
</template>

<script>
import NavbarItem from "@/components/navbar/NavbarItem.vue";
export default {
    components: { NavbarItem },
    props: {
        activePage: String,
    },
    data() {
        return {
            navbarVisible: false,
        }
    },
    methods: {
        toggleNavbar() {
            this.navbarVisible = !this.navbarVisible;
        }
    }
}
</script>

<style>
</style>