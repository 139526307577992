import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Contacts from "@/views/Contacts.vue";

const routes = [
    { path: "/", name: "Home", component: Home },
    { path: "/contactos", name: "Contacts", component: Contacts },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;